<template lang="">
  <div>
    <FilterList
      :filter="filter"
      :status="status"
      @onCreate="()=>supportReqId = null"
      @refresh="fetchItem"
      @update-filter="(val)=> filter = val"
    />

    <!-- Table -->

    <b-card no-body>
      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
      >
        <b-table
          ref="refDataTable"
          bordered
          no-border-collapse
          sticky-header
          responsive
          show-empty
          primary-key="id"
          style="max-height: 70vh"
          class="position-relative"
          table-class="table-resell-ticket"
          :items="items"
          :fields="columns"
          :empty-text="$t('noRecordFund')"
          :sort-by.sync="filter.sortBy"
          :sort-desc.sync="filter.isSortDirDesc"
          @row-clicked="(val)=>{
            if(clickedId != null && clickedId == val.id)
              clickedId = null
            else
              clickedId = val.id
          }"
        >
          <!-- ANCHOR Header -->
          <template
            v-for="column in columns"
            #[`head(${column.key})`]="data"
          >
            <div
              :key="column.label"
              class="text-dark text-nowrap text-center"
            >
              {{ $t(`supportReq.columns.${data.label}`) }}
            </div>
          </template>

          <!-- ANCHOR Value -->
          <template
            v-for="column in columns"
            #[`cell(${column.key})`]="{ item }"
          >
            <div
              :key="column.key"
              class="text-nowrap"
              :class="{
                'text-right': typeof item[column.key] === 'number',
              }"
            >
              {{ item[column.key] }}
            </div>
          </template>

          <!-- ANCHOR stt. -->
          <template #cell(stt)="data">
            <span class="text-right">
              {{ data.index + 1 }}</span>
          </template>

          <!-- ANCHOR createdAt. -->
          <template #cell(createdAt)="{ item }">
            <span class="text-right">
              {{ convertISODateTime(item.createdAt).dateTime }}
            </span>
          </template>

          <!-- ANCHOR status. -->
          <template #cell(status)="{ item }">
            <span class="text-right">
              {{ $t(`supportReq.status.${item.status}`) }}
            </span>
          </template>

          <!-- ANCHOR content. -->
          <template #cell(content)="{ item }">
            <span
              v-if="item.content"
              :class="clickedId != item.id ? 'two-line-ellipsis':''"
            >
              {{ item.content }}
            </span>
          </template>

          <!-- ANCHOR note. -->
          <template #cell(note)="{ item }">
            <span
              v-if="item.note"
              :class="clickedId != item.id ? 'two-line-ellipsis':''"
            >
              {{ item.note }}
            </span>
          </template>

          <template #cell(flight)="{item}">
            <div
              v-for="(trip, index) of resolveBookingRequest(item.bookingInfo.bookingRequest.itineraries)"
              :key="index"
              class="text-nowrap text-body-3"
            >
              <div
                v-for="(segment,indexSegment) in trip.segments"
                :key="indexSegment"
                class="d-flex flex-nowrap my-50"
              >
                <IAmLogoAirline
                  v-if="segment.airline"
                  :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                  size="xs"
                  rounded
                  custom-class="mr-50"
                />
                <span>
                  {{ segment.devSegmentIndex }}
                  {{ `${resolveAirlineFlightNumber(segment.airline, segment.flightNumber)}` }}
                  {{ segment.bookingClass }}
                  {{ convertISODateTime(segment.departureTime).dayMonth }}
                  {{ `${segment.departure}${segment.arrival}` }}
                  {{ convertISODateTime(segment.departureTime).hourMin }}
                  {{ convertISODateTime(segment.arrivalTime).hourMin }}
                </span>
              </div>
            </div>
          </template>

          <!-- <template #cell(createdBy)="data"> -->
          <template #cell(createdBy)="data">
            <div
              :id="`booker-${data.item.createdBy.id}-${data.item.createdAt}`"
              class="w-100 h-100 d-flex-center flex-column"
            >
              <!-- username -->
              <div class="text-info font-weight-bold text-body-3 text-nowrap">
                {{ data.item.createdBy.username.toUpperCase() }}
              </div>

              <!-- agency -->
              <div class="text-dark font-weight-bold text-body-3 text-nowrap">
                ({{
                  isRoleF1 && data.item.agency.parentAgency && data.item.agency.parentAgency.id !== 1000000
                    ? data.item.agency.parentAgency.agencyCode.toUpperCase()
                    : data.item.agency.agencyCode.toUpperCase()
                }})
              </div>
              <b-tooltip
                :target="`booker-${data.item.createdBy.id}-${data.item.createdAt}`"
                triggers="hover"
                placement="top"
                boundary="viewport"
                variant="dark"
              >
                <BRow>
                  <BCol
                    cols="4"
                    class="text-nowrap"
                  >
                    Tên tài khoản:
                  </BCol>
                  <BCol cols="8">
                    <span class="font-weight-bolder">
                      {{ data.item.createdBy.lastName }}
                      {{ data.item.createdBy.firstName }}
                      ({{ data.item.createdBy.type }})
                    </span>
                  </BCol>
                  <BCol
                    cols="4"
                    class="text-nowrap"
                  >
                    Mã tài khoản:
                  </BCol>
                  <BCol cols="8">
                    <span class="font-weight-bolder">{{ data.item.createdBy.employeeCode.toUpperCase() }}</span>
                  </BCol>
                  <BCol
                    cols="4"
                    class="text-nowrap"
                  >
                    Tên đại lý:
                  </BCol>
                  <BCol cols="8">
                    <span class="font-weight-bolder">{{ data.item.agency.agencyName }}</span>
                  </BCol>
                  <BCol
                    cols="4"
                    class="text-nowrap"
                  >
                    Mã đại lý:
                  </BCol>
                  <BCol cols="8">
                    <span class="font-weight-bolder">{{ data.item.agency.agencyCode.toUpperCase() }}</span>
                  </BCol>
                  <BCol
                    v-if="isRoleF1 && data.item.agency.parentAgency && data.item.agency.parentAgency.id !== 1000000"
                    cols="4"
                    class="text-nowrap"
                  >
                    Tên ĐL chủ quản:
                  </BCol>
                  <BCol
                    v-if="isRoleF1 && data.item.agency.parentAgency && data.item.agency.parentAgency.id !== 1000000"
                    cols="8"
                  >
                    <span class="font-weight-bolder">{{ data.item.agency.parentAgency.agencyName }}</span>
                  </BCol>
                  <BCol
                    v-if="isRoleF1 && data.item.agency.parentAgency && data.item.agency.parentAgency.id !== 1000000"
                    cols="4"
                    class="text-nowrap"
                  >
                    Mã ĐL chủ quản:
                  </BCol>
                  <BCol
                    v-if="isRoleF1 && data.item.agency.parentAgency && data.item.agency.parentAgency.id !== 1000000"
                    cols="8"
                  >
                    <span class="font-weight-bolder">{{ data.item.agency.parentAgency.agencyCode.toUpperCase() }}</span>
                  </BCol>
                </BRow>
              </b-tooltip>
            </div>
          </template>
          <!-- ANCHOR agency. -->
          <template #cell(create)="{ item }">
            <span
              v-if="item.agency"
              class="text-right"
            >
              {{ item.agency?.agencyName }} ({{ item.agency?.agencyCode }})
            </span>
          </template>

          <!-- ANCHOR airline. -->
          <template #cell(airline)="{ item }">
            <span
              v-if="item.airline"
              class="text-right"
            >
              {{ item.airline?.name }} ({{ item.airline?.code }})
            </span>
          </template>

          <!-- ANCHOR pnr. -->
          <template #cell(pnr)="{ item }">
            <b-link
              :to="{ name: 'apps-reservations-modify', params: { id: item.bookingInfo.id } }"
              target="_blank"
              class="font-weight-bold"
            >
              {{ item.pnr }}
            </b-link>
          </template>

          <!-- ANCHOR createdBy. -->
          <!-- <template #cell(createdBy)="{ item }">
            <span class="text-right">
              {{ item.createdBy?.lastName }} {{ item.createdBy?.firstName }} ({{ item.agency?.agencyName }})
            </span>
          </template> -->

          <!-- ANCHOR actions. -->
          <template #cell(actions)="{ item }">
            <div class="d-flex gap-1">
              <!-- <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                size="md"
                variant="outline-info"
                @click="()=>$router.push({ name: 'apps-support-detail', params: { id: item.id } })"
              >
                {{ $t('supportReq.btn.detail') }}
              </b-button> -->

              <!-- ANCHOR btnEdit. -->
              <b-button
                v-if="item.status == 'PENDING'"
                id="btnEdit"
                v-b-tooltip.hover.v-dark.window="$t('supportReq.btn.edit')"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="flat-primary"
                class="py-50 px-75 rounded"
                size="md"
                @click="() => {
                  supportReqId = item.id
                  $bvModal.show('create-support-modal')
                }"
              >
                <IAmIcon
                  icon="editOutline"
                  size="18"
                />
              </b-button>

              <!-- ANCHOR btnReject. -->
              <b-button
                v-if="isRoleF1 && item.status === 'PENDING'"
                id="btnReject"
                v-b-tooltip.hover.v-dark.window="$t('supportReq.btn.reject')"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="flat-danger"
                class="py-50 px-75 rounded"
                size="md"
                @click="handleChangeStatus(item,'REJECTED')"
              >
                <IAmIcon
                  icon="rejected"
                  size="18"
                />
              </b-button>

              <!-- ANCHOR btnSelfHandle. -->
              <b-button
                v-if="isRoleF1 && item.status === 'PENDING'"
                id="btnSelfHandle"
                v-b-tooltip.hover.v-dark.window="$t('supportReq.status.SELF_HANDLED')"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="flat-primary"
                class="py-50 px-75 rounded"
                size="md"
                @click="handleChangeStatus(item,'SELF_HANDLED')"
              >
                <IAmIcon
                  icon="userOutline"
                  size="18"
                />
              </b-button>

              <!-- ANCHOR btnComplete. -->
              <b-button
                v-if="item.status === 'PENDING' && isRoleF1"
                id="btnComplete"
                v-b-tooltip.hover.v-dark.window="$t('supportReq.status.COMPLETED')"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="flat-success"
                class="py-50 px-75 rounded"
                size="md"
                @click="handleChangeStatus(item,'COMPLETED')"
              >
                <IAmIcon
                  icon="checked"
                  size="18"
                />
              </b-button>

              <!-- ANCHOR canceled. -->
              <b-button
                v-if="item.status === 'PENDING' && isRoleF2"
                id="canceled"
                v-b-tooltip.hover.v-dark.window="$t('supportReq.btn.cancel')"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="flat-danger"
                class="py-50 px-75 rounded"
                size="md"
                @click="handleChangeStatus(item,'CANCELED')"
              >
                <IAmIcon
                  icon="closeOutline"
                  size="18"
                />
              </b-button>

              <!-- ANCHOR btnDetail. -->
              <b-button
                id="btnDetail"
                v-b-tooltip.hover.v-dark.window="$t('resellTicket.btn.logsDetail')"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="flat-info"
                class="py-50 px-75 rounded"
                size="md"
                @click="detailLogsHandle(item.id)"
              >
                <IAmIcon
                  icon="receipt"
                  size="18"
                />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-overlay>
      <!-- paging -->
      <b-container
        fluid
        class="bg-white py-1"
      >
        <b-row>
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-center"
          >
            <span class="text-muted">
              {{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }} {{ $t('paginationText.outOf') }}
              {{ dataMeta.of }} {{ $t('paginationText.items') }}
            </span>
          </b-col>
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="filter.page"
              :total-rows="total"
              :per-page="filter.size"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <v-select
              v-model="filter.size"
              :options="sizePerPageLgOptions"
              class="per-page-selector "
              :clearable="false"
            >
              <template #option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <!-- embed -->
    <CreateModal
      :id.sync="supportReqId"
      @refresh="fetchItem()"
    />
    <LogModal :logs.sync="supportReqLogs" />

    <BModal
      id="modal-actions"
      :title="$t('resellTicket.btn.logsDetail')"
      :title-class="`text-airline font-weight-bolder ${isMobileView ? 'font-medium-2' : 'font-medium-4'}`"
      body-class="p-1"
      size="lg"
      centered
      @ok="updateHandle"
    >
      <span
        v-if="updateStatus"
        class="font-weight-bolder"
      >
        {{ $t('modal.changeStatusWithText',{text:$t(`supportReq.status.${updateStatus}`)}) }}
      </span>

      <!-- ANCHOR content -->
      <b-col
        sm="12"
        class="mt-2"
      >
        <b-form-group label-for="content">
          <template #label>
            {{ $t('supportReq.form.content') }}
          </template>
          <b-form-textarea
            id="content"
            v-model="updateItem.content"
            :placeholder="$t('supportReq.form.content')"
            :name="$t('supportReq.form.content')"
            :disabled="isRoleF1"
            rows="5"
          />
        </b-form-group>
      </b-col>

      <!-- ANCHOR note -->
      <b-col
        v-if="isRoleF1"
        sm="12"
      >
        <b-form-group label-for="note">
          <template #label>
            {{ $t('supportReq.form.note') }}
          </template>
          <b-form-textarea
            id="note"
            v-model="updateItem.note"
            :placeholder="$t('supportReq.form.note')"
            :name="$t('supportReq.form.note')"
            rows="5"
          />
        </b-form-group>
      </b-col>
    </BModal>
  </div>
</template>
<script>
import { computed, ref } from '@vue/composition-api'
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormGroup,
  BFormTextarea,
  BLink, BOverlay, BPagination,
  BRow,
  BTable,
  BTooltip,
} from 'bootstrap-vue'
import { cloneDeep } from 'lodash'

import { convertISODateTime, resolveAirlineFlightNumber } from '@/@core/utils/filter'
import IAmLogoAirline from '@/components/IAmLogoAirline.vue'
import { sizePerPageLgOptions } from '@/constants/selectOptions'
import store from '@/store'

import useSupportReqHandle from '../useSupportReqHandle'
import useListHandle from './useListHandle'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BLink,
    BPagination,
    BCol,
    BRow,
    BContainer,
    BOverlay,
    IAmLogoAirline,
    BFormGroup,
    BFormTextarea,
    BTooltip,
    vSelect: () => import('vue-select'),
    FilterList: () => import('@supportRequest/list/FilterList.vue'),
    CreateModal: () => import('@supportRequest/create/CreateModal.vue'),
    LogModal: () => import('@supportRequest/list/LogModal.vue'),
  },
  setup() {
    const {
      status, resolveBookingRequest,
    } = useSupportReqHandle()
    const {
      loading,
      filter,
      items,
      totalItem: total,
      columns,
      fetchItem,
      getLogsById,
      changeStatus,
    } = useListHandle()
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    const refDataTable = ref()
    const clickedId = ref(null)
    const supportReqId = ref(null)
    const supportReqLogs = ref([])
    const updateItem = ref({ content: '', note: '' })
    const updateStatus = ref(null)
    const dataMeta = computed(() => {
      const { page, size } = filter.value
      const localItemsCount = refDataTable.value
        ? refDataTable.value?.localItems?.length
        : 0
      return {
        from: size * (page - 1) + (localItemsCount ? 1 : 0) || 0,
        to: size * (page - 1) + localItemsCount,
        of: total.value,
      }
    })
    function handleChangeStatus(item, status) {
      updateItem.value = cloneDeep(item)
      updateStatus.value = status
      this.$bvModal.show('modal-actions')
    }

    function updateHandle() {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.changeStatusWithText', { text: this.$t(`supportReq.status.${updateStatus.value}`) }), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('modal.iamConfirm'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            changeStatus(updateItem.value.id, updateStatus.value, updateItem.value.note).then(() => {
              fetchItem()
            })
          }
        })
    }
    async function detailLogsHandle(id) {
      const rs = await getLogsById(id)
      supportReqLogs.value = rs
      this.$bvModal.show('modal-logs')
    }
    fetchItem(true)
    return {
      isRoleF1,
      isRoleF2,
      clickedId,
      loading,
      columns,
      items,
      total,
      filter,
      status,
      dataMeta,
      refDataTable,
      supportReqId,
      supportReqLogs,

      convertISODateTime,
      sizePerPageLgOptions,

      fetchItem,
      handleChangeStatus,
      resolveAirlineFlightNumber,
      resolveBookingRequest,
      detailLogsHandle,
      updateItem,
      updateStatus,
      updateHandle,
    }
  },
}
</script>
<style lang="scss" scoped>
.two-line-ellipsis{
  overflow: hidden;
  max-width: 740px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
}
</style>
